import React, {useContext, useState, useMemo, useCallback, useEffect} from 'react';
import {Grid, Label, Menu, Loader, Button, Select, Dimmer, Icon, Confirm} from 'semantic-ui-react'
import { useToasts } from 'react-toast-notifications'

import range from 'lodash/range'
import merge from 'lodash/merge'
import get from 'lodash/get'
import moment from 'moment'
import {saveReport, database, getNotifications} from './fb'
import {UserContext, IsAdminContext, HolidaysContext} from './DataContextProvider'
import {calculateRows, calculateStats} from './utils'
import NoteModal from './NoteModal';


const start = () => {
  return range(7, 21).map(t => {
    return {
      key: t,
      value: t,
      text: moment().hour(t).minute(0).format('HH:mm')
    }
  })
}



const Report = ({month, year, userId}) => {
  const [reportData, setReportData] = useState({})
  const [loading, setLoading] = useState(true)
  const [noteModalOpen, setNoteModalOpen] = useState(null)
  const [confirmSubmit, setConfirmSubmit] = useState(false)
  const [user] = useContext(UserContext)
  const [holidaysData] = useContext(HolidaysContext)
  const [isAdmin] = useContext(IsAdminContext)
  const submitted = get(reportData, 'submitted.status.submittedOn')
  const submittedBy = get(reportData, 'submitted.status.by')
  const { addToast } = useToasts()

  useEffect(() => {
    const ref = database.ref(`reports/${month}-${year}/${userId}`)
    
 
    ref.on('value', (snapshot) => {
      setReportData(snapshot.val())
      setLoading(false)
    })
    return () => {
      ref.off()
      setLoading(true)
    }
  }, [month, userId, year])

  const rows = useMemo(() => {
    return calculateRows(month, year, holidaysData, reportData)
  }, [holidaysData, month, reportData, year])

  const stats = useMemo(() => {
    return calculateStats(reportData, rows)
  }, [reportData, rows])

  const handleChange = useCallback((prop, key, data) => {
    if (submitted && !isAdmin) {
      addToast('Unable to add changes after submittion. Please contact the office manager', { appearance: 'warning', placement: 'top-right', autoDismiss: true, autoDismissTimeout: 3000 })
      return
    }
    const _data = merge({}, reportData, {
      [key]: {
        [prop]: data.value || data
      }
    })
    // setReportData(_data)
    saveReport(userId, month, year, _data)
  }, [addToast, isAdmin, month, reportData, submitted, userId, year])


  return (
    <div>
      <Dimmer inverted active={loading} verticalAlign='top'>
        <Loader />
      </Dimmer>

      <div fullWidth>
        <Grid verticalAlign="middle">
          <Grid.Column floated="left" mobile={4} computer={4}>
            {submitted ? <Label as='a' color='green' ribbon>By {submittedBy} on {moment(submitted).format('DD-MM-YYYY dddd HH:mm')}</Label> : <Button color='green' onClick={() => setConfirmSubmit(true)}>Approve & Submit</Button>}
            <Confirm open={confirmSubmit} onCancel={() => setConfirmSubmit(false)} onConfirm={() => {
              handleChange('status', `submitted`, {submittedOn: new Date(), by: user.displayName})
              setConfirmSubmit(false)
            }} />
          </Grid.Column>
          <Grid.Column floated="right" mobile={12} computer={12} textAlign="right" verticalAlign="middle">
            <span className="statLabel">Days Off: {stats.daysOff}</span>
            <span className="statLabel">Holidays: {stats.holidays}</span>
            <span className="statLabel">Sick: {stats.sickDays}</span>
            <span className="statLabel">Military: {stats.militaryDays}</span>
            <span className="statLabel">Days: {stats.workDays}</span>
            <span className="statLabel">Hours: {stats.hours}</span>
          </Grid.Column>
        </Grid>
        {rows.map(m => {
          const color = m.diff > 4.5 ? 'green' : 'yellow'
          const color2 = !m.isPresent && 'red'
          return (
            <Grid key={m.key} verticalAlign="middle" className={m.isHoliday ? 'gridRowStyle reportHolidayRow' : (m.isDisabled ? 'gridRowStyle reportWeekendRow' : 'gridRowStyle')}>
              <Grid.Column mobile={6} tablet={4} computer={4} largeScreen={4}>
                {!m.isDisabled && <Label circular color={color2 || color} key={color2 || color}>{m.diff}</Label>}
                <span style={{marginLeft: '10px'}}>{m.date.format('DD ddd')} </span>
                {!m.isDisabled && <a href="#" onClick={() => setNoteModalOpen({key: m.key, text: get(m, 'note.text', ''), formattedDate: m.date.format('DD ddd MMMM')})}>
                  <Icon color={get(m, 'note.text') ? 'blue' : 'grey'} name={get(m, 'note.text') ? "sticky note" : "sticky note outline"} />
                </a>}
              </Grid.Column>
              {m.isHoliday && <Grid.Column mobile={10} tablet={12} computer={12} largeScreen={12}>{m.holiday}</Grid.Column>}
              {!m.isDisabled && <Grid.Column mobile={5} tablet={3} computer={3} largeScreen={3}><Select compact placeholder='Start' options={start()} value={m.start} onChange={(_, data) => handleChange('start', m.key, data)} /></Grid.Column>}
              {!m.isDisabled && <Grid.Column mobile={5} tablet={3} computer={3} largeScreen={3}><Select compact placeholder='end' options={start()} value={m.end} onChange={(_, data) => handleChange('end', m.key, data)} /></Grid.Column>}

              {!m.isDisabled && <Grid.Column mobile={16} tablet={5} computer={5} largeScreen={6} className="full">
                <Menu color={'green'} widths={4} fluid size="mini">
                  <Menu.Item active={m.isPresent} onClick={() => handleChange('type', m.key, 'present')}>
                    <span>Present</span>
                  </Menu.Item>
                  <Menu.Item active={get(reportData, [m.key, 'type']) === 'dayoff'} onClick={() => handleChange('type', m.key, 'dayoff')}>
                    <span>Day Off</span>
                  </Menu.Item>
                  <Menu.Item active={get(reportData, [m.key, 'type']) === 'sick'} onClick={() => handleChange('type', m.key, 'sick')}>
                    <span>Sick</span>
                  </Menu.Item>
                  <Menu.Item active={get(reportData, [m.key, 'type']) === 'military'} onClick={() => handleChange('type', m.key, 'military')}>
                    <span>Military</span>
                  </Menu.Item>
                </Menu>
              </Grid.Column>}
            </Grid>
          )
        })}
        <Grid verticalAlign="middle">
          <Grid.Column floated="left" computer={8}>
            {submitted && <Label as='a' size="medium">Submitted by {submittedBy} on {moment(submitted).format('DD-MM-YYYY dddd HH:mm')}</Label>}
          </Grid.Column>
          <Grid.Column floated="right" computer={8} textAlign="right">

          </Grid.Column>
        </Grid>
      </div>
      <NoteModal title={`Add a note ${get(noteModalOpen, 'formattedDate', '')}`} open={get(noteModalOpen, 'key')} value={get(noteModalOpen, 'text')} onClose={() => setNoteModalOpen(null)} onSave={(data) => handleChange('note', noteModalOpen.key, {text: data.text, ceratedOn: new Date()})} />
    </div>
  )
}
export default Report
import React, { useContext, useCallback, useMemo, useState } from 'react';
import { Grid, Card, Menu, Button, Label, Modal, Input } from 'semantic-ui-react'
import { withRouter } from "react-router";
import moment from 'moment'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";


const HolidaysModal = props => {
  const [date, setDate] = useState(new Date())
  const [name, setName] = useState('')
  return (
    <Modal size={'tiny'} open={props.open} onClose={props.onClose}>
      <Modal.Header>Holiday Description</Modal.Header>
      <Modal.Content>
        <p>
          <DatePicker
            selected={date}
            startDate={date}
            onChange={(d) => setDate(d)}
          />
        </p>
        <p>
          <Input placeholder="Holiday Name" fluid onChange={(_, data) => setName(data.value)} />
        </p>

      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.onClose} negative>No</Button>
        <Button onClick={() => {
          props.onSave({ name, date })
          props.onClose()
        }} positive icon='checkmark' labelPosition='right' content='Yes' />
      </Modal.Actions>
    </Modal>
  )
}


export default HolidaysModal
import React, { useContext, useCallback, useEffect, useState } from 'react';
import { Image, Button, Dimmer, Loader, Grid } from 'semantic-ui-react'
import { withRouter, matchPath } from "react-router";
import moment from 'moment'
import get from 'lodash/get'
import {
  Route,
  Redirect
} from "react-router-dom";
import { UserContext, UsersContext, IsAdminContext, HolidaysContext } from './DataContextProvider'
import { logout, getUsers, getIsAdmin, database } from './fb'
import View from './View';
import MonthlyReport from './MonthlyReport';



const Layout = props => {

  const pathName = props.history.location.pathname
  const match = matchPath(pathName, { path: '/:userId/:month/:year', exact: true, strict: false })
  const matchMonthlyReport = matchPath(pathName, { path: '/reports/monthly/:month/:year', exact: true, strict: false })

  const [user, setUser] = useContext(UserContext)
  const [, setUsers] = useContext(UsersContext)
  const [, setIsAdmin] = useContext(IsAdminContext)
  const [, setHolidayData] = useContext(HolidaysContext)
  const [loading, setLoading] = useState(true)

  const selectedMonth = get(match || matchMonthlyReport, 'params.month')
  const selectedYear = get(match || matchMonthlyReport, 'params.year')

  useEffect(() => {
    const ref = database.ref(`holidays/${selectedMonth}-${selectedYear}`)
    ref.on('value', (snapshot) => {
      const map = snapshot.val() || {}
      setHolidayData({
        map,
        list: Object.keys(map).map(k => ({ ...map[k], key: k }))
      })
    })
    return () => {
      ref.off()
    };
  }, [selectedMonth, selectedYear, setHolidayData])

  useEffect(() => {
    Promise.all([getUsers(), getIsAdmin(user.uid)]).then(([users, isAdmin]) => {
      setUsers(users)
      setIsAdmin(isAdmin)
      setLoading(false)
    })
  }, [setIsAdmin, setUsers, user.uid])


  const handleLogout = useCallback(() => {
    logout().then(() => {
      setUser({})
    })
  }, [setUser])



  if (pathName === '/') {
    return <Redirect to={`${user.id}/${moment().format('M')}/${moment().format('YYYY')}`} />
  }
  return (
    <div>
      <Dimmer inverted active={loading} verticalAlign='top'>
        <Loader />
      </Dimmer>
      <Grid relaxed className="appHeader" verticalAlign="middle" padded={false} verticalAlign="middle">
        <Grid.Column computer={4} mobile={5} floated="left">
          <img style={{ width: "100px", marginTop: "5px" }} src={'https://rapidapi.com/static/rapidapi/logo.svg'} />
        </Grid.Column>
        <Grid.Column computer={12} mobile={11} floated="right" textAlign="right" verticalAlign="middle">
          <Image className="userAvatar" src={user.photoURL} avatar />
          <span className="userName">{user.displayName}</span>
          <Button size="mini" basic color='green' onClick={handleLogout}>Logout</Button>
        </Grid.Column>
      </Grid>

      <div className="body">
        <Route path={`/reports/monthly/:month/:year`} exact render={() => <MonthlyReport selectedMonth={get(matchMonthlyReport, 'params.month')} selectedYear={get(matchMonthlyReport, 'params.year')} />} />
        <Route path={`/:userId/:month/:year`} exact render={() => <View selectedUser={get(match, 'params.userId')} selectedMonth={get(match, 'params.month')} selectedYear={get(match, 'params.year')} />} />
      </div>
    </div>
  )
}


export default withRouter(Layout)
import React, {useContext, useCallback, useEffect, useState} from 'react';
import moment from 'moment'
import {Grid, Icon, List, Image, Button, Select, Header, Message} from 'semantic-ui-react'
import HolidaysModal from './HolidaysModal'
import {saveHoliday, database, deleteHoliday} from './fb'
import {HolidaysContext, IsAdminContext} from './DataContextProvider';

const Holidays = ({selectedMonth, selectedYear}) => {
  const [openHolidayModal, setOpenHolidayModal] = useState(false)
  const [holidayData] = useContext(HolidaysContext)
  const [isAdmin] = useContext(IsAdminContext)

  const handleSaveHoliday = useCallback((data) => {
    saveHoliday(selectedMonth, selectedYear, data)
  }, [selectedMonth, selectedYear])

  const handleDeleteHoliday = useCallback((date) => {
    deleteHoliday(selectedMonth, selectedYear, date)
  }, [selectedMonth, selectedYear])

  return (
    <div className="full">
      <Header as='h4' style={{marginTop: '30px'}}>Holidays</Header>
      {holidayData.list.length === 0 && <Message content='No Holidays this month' />}
      <List>
        {holidayData.list.map(h => {
          return (
            <List.Item key={h.key} className="spaceBetween">
              <span>{moment(h.date).format('DD/MM')} {h.name}</span>
              {isAdmin && <a href="#" onClick={() => handleDeleteHoliday(h.key)}>
                <Icon color="red" name="remove" />
              </a>}
            </List.Item>
          )
        })}
      </List>
      {isAdmin && <Button fluid onClick={() => setOpenHolidayModal(true)} primary>Add Holidays</Button>}
      <HolidaysModal open={openHolidayModal} onClose={() => setOpenHolidayModal(false)} onSave={handleSaveHoliday} />
    </div>
  )
}


export default Holidays
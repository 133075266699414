import React from 'react';
import {ToastProvider} from 'react-toast-notifications'

import ReactDOM from 'react-dom';
import App from './App';
import DataContextProvider from './DataContextProvider'



const MainAppWrapper = props => {
  return (

    <DataContextProvider>
      <ToastProvider>
        <App />
      </ToastProvider>
    </DataContextProvider>
  )
}


ReactDOM.render(<MainAppWrapper />, document.getElementById('root'));
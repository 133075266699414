import React, { useContext, useCallback, useMemo, useEffect, useState } from 'react';
import { Grid, Card, Table, Image, Breadcrumb, Dimmer, Loader, Icon } from 'semantic-ui-react'
import range from 'lodash/range'
import { withRouter } from "react-router";
import moment from 'moment'
import { UsersContext, IsAdminContext, HolidaysContext } from './DataContextProvider'
import MonthMenu from './MonthMenu'
import Holidays from './Holidays'
import { getAllUsersMonthlyData } from './fb'
import { calculateRows, calculateStats } from './utils'
import get from 'lodash/get';



const MonthlyReport = props => {

  const [users] = useContext(UsersContext)
  const [isAdmin] = useContext(IsAdminContext)
  const [holidaysData] = useContext(HolidaysContext)
  const [overrides, setOverrides] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getAllUsersMonthlyData(props.selectedMonth, props.selectedYear).then(results => {
      setOverrides(results)
      setLoading(false)
    })
    return () => {
      setLoading(true)
    }
  }, [props.selectedMonth, props.selectedYear])

  const handleSelect = useCallback((selectedMonth, selectedYear) => {
    props.history.push(`/reports/monthly/${selectedMonth}/${selectedYear}`)
  }, [props.history])

  const _users = useMemo(() => {
    return users.map(u => {
      const override = overrides ? overrides[u.id] : {}
      console.log(holidaysData)
      const rows = calculateRows(props.selectedMonth, props.selectedYear, holidaysData, override || {})
      const stats = calculateStats(override || {}, rows)
      return {
        ...u,
        stats
      }
    })
  }, [holidaysData, overrides, props.selectedMonth, props.selectedYear, users])


  const friendlyDate = moment().month(props.selectedMonth).year(props.selectedYear).format('MMMM YYYY')
  return (
    <div className="full">
      <Grid>
        <Grid.Column computer={16} largeScreen={16}>
          <Breadcrumb>
            <Breadcrumb.Section link onClick={() => props.history.push(`/`)}>Home</Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section>Monthly Report - {friendlyDate}</Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column computer={13} largeScreen={13} id="report">
          <Card fluid>
            <Dimmer inverted active={loading} verticalAlign='top'>
              <Loader />
            </Dimmer>
            <Card.Content>
              <Table basic='very'>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Employees</Table.HeaderCell>
                    <Table.HeaderCell>Submitted</Table.HeaderCell>
                    <Table.HeaderCell>Hours</Table.HeaderCell>
                    <Table.HeaderCell>Days</Table.HeaderCell>
                    <Table.HeaderCell>Days Off</Table.HeaderCell>
                    <Table.HeaderCell>Sick</Table.HeaderCell>
                    <Table.HeaderCell>Military</Table.HeaderCell>
                    <Table.HeaderCell>Holidays</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_users.map(user => {
                    return (
                      <Table.Row key={user.id} positive={user.stats.isSubmitted} negative={!user.stats.isSubmitted}>
                        <Table.Cell> <Image avatar src={user.photoURL} /> {user.displayName}</Table.Cell>
                        <Table.Cell>{user.stats.isSubmitted ? <Icon name="checkmark" color="green"/> : <Icon name="close" color="red"/>}</Table.Cell>
                        <Table.Cell>{get(user, 'stats.hours', 0)}</Table.Cell>
                        <Table.Cell>{get(user, 'stats.workDays', 0)}</Table.Cell>
                        <Table.Cell>{get(user, 'stats.daysOff', 0)}</Table.Cell>
                        <Table.Cell>{get(user, 'stats.sickDays', 0)}</Table.Cell>
                        <Table.Cell>{get(user, 'stats.militaryDays', 0)}</Table.Cell>
                        <Table.Cell>{get(user, 'stats.holidays', 0)}</Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table>
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column computer={3} largeScreen={3}>
          <MonthMenu onSelect={handleSelect} selectedMonth={props.selectedMonth} selectedYear={props.selectedYear} />
          <Holidays selectedMonth={props.selectedMonth} selectedYear={props.selectedYear} />
        </Grid.Column>
      </Grid>

    </div>
  )
}


export default withRouter(MonthlyReport)
import React, {useContext, useCallback, useMemo, useState, useEffect} from 'react';
import {Grid, Card, Menu, Image, List, Select, Header, Icon, Message, Button} from 'semantic-ui-react'
import {withRouter} from "react-router";
import moment from 'moment'
import {UsersContext, IsAdminContext, UserContext} from './DataContextProvider'
import Report from './Report'
import Holidays from './Holidays'
import MonthMenu from './MonthMenu'
import {saveNotification, getNotificationsRef, deleteNotification} from './fb';
import get from 'lodash/get';
import NoteModal from './NoteModal';

const View = props => {

  const [users] = useContext(UsersContext)
  const [isAdmin] = useContext(IsAdminContext)
  const [user] = useContext(UserContext)
  const [notificationModalOpen, setNotificationModalOpen] = useState(false)
  const [notifications, setNotifications] = useState({})
  const selectedDate = moment().month(props.selectedMonth - 1).year(props.selectedYear)

  useEffect(() => {
    const ref = getNotificationsRef(props.selectedMonth, props.selectedYear)
    ref.on('value', snapshot => {
      setNotifications(snapshot.val() || {})
    })
    return () => {
      ref.off()
    }
  }, [props.selectedMonth, props.selectedYear])

  const handleSelect = useCallback((selectedMonth, selectedYear) => {
    props.history.push(`/${props.selectedUser}/${selectedMonth}/${selectedYear}`)
  }, [props.history, props.selectedUser])

  const handleUserSelect = useCallback((userId) => {
    props.history.push(`/${userId}/${props.selectedMonth}/${props.selectedYear}`)
  }, [props.history, props.selectedMonth, props.selectedYear])

  const handleSaveNotification = useCallback((text) => {
    saveNotification(text, props.selectedMonth, props.selectedYear)
  }, [props.selectedMonth, props.selectedYear])

  const _users = useMemo(() => {
    if (!isAdmin) {
      return users.filter(u => u.id === user.id)
    }
    return users
  }, [isAdmin, user.id, users])

  const _notifications = useMemo(() => {
    return Object.keys(notifications).map(key => {
      return (
        <Message icon color='olive' size="mini">
          <Icon size="tiny" name="attention" />
          <Message.Content>{get(notifications, [key, 'text'], '')}</Message.Content>
          <a href="#" onClick={() => deleteNotification(key, props.selectedMonth, props.selectedYear)}>
            <Icon color="red" name="remove" />
          </a>
        </Message>
      )
    })
  }, [notifications, props.selectedMonth, props.selectedYear])

  return (
    <div className="full">
      <Grid>
        <Grid.Column only="mobile tablet" width={8}>
          <Select fluid options={_users.map(u => ({id: u.id, value: u.id, text: u.displayName}))} value={props.selectedUser} onChange={(_, data) => handleUserSelect(data.value)} />
        </Grid.Column>
        <Grid.Column only="mobile tablet" width={8}>
          <MonthMenu onSelect={handleSelect} selectedMonth={props.selectedMonth} selectedYear={props.selectedYear} isMobile />
        </Grid.Column>
        <Grid.Column only="computer largeScreen" computer={3} largeScreen={3}>
          <Menu color={'green'} vertical fluid>
            {_users.map(u => <Menu.Item active={u.id === props.selectedUser} key={u.id} onClick={() => handleUserSelect(u.id)}>
              <Image avatar src={u.photoURL} />
              <span>{u.displayName}</span>
            </Menu.Item>)}
          </Menu>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={10} largeScreen={10} id="report">
          {_notifications}
          <Card fluid>
            <Card.Content>
              <Report month={props.selectedMonth} year={props.selectedYear} userId={props.selectedUser} />
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column only="computer largeScreen" tablet={0} computer={3} largeScreen={3}>
          <MonthMenu onSelect={handleSelect} selectedMonth={props.selectedMonth} selectedYear={props.selectedYear} />
          {isAdmin && <div>
            <Header as="h4" style={{marginTop: '30px'}}>Actions</Header>
            <List>
              <List.Item onClick={() => props.history.push(`/reports/monthly/${props.selectedMonth}/${props.selectedYear}`)}>
                <List.Content>
                  <a href="#">Monthly Report (Summary)</a>
                </List.Content>
              </List.Item>

              <List.Item onClick={() => setNotificationModalOpen(true)}>
                <List.Content>
                  <a href="#">Add Notification</a>
                </List.Content>
              </List.Item>
            </List>
          </div>}
          <Holidays selectedMonth={props.selectedMonth} selectedYear={props.selectedYear} />
          <Header as="h4" style={{marginTop: '30px'}}>Notifications</Header>
          <Button color='red' fluid>
            <Icon name='bell' /> Send Reminders
          </Button>
        </Grid.Column>
      </Grid>
      <NoteModal title={`Notification for ${selectedDate.format('MMMM YYYY')}`} open={notificationModalOpen} onClose={() => setNotificationModalOpen(false)} onSave={handleSaveNotification} />
    </div>
  )
}


export default withRouter(View)
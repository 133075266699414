import range from 'lodash/range'
import moment from 'moment'
import get from 'lodash/get'

export const calculateRows = (month, year, holidaysData, reportData) => {
  const d = moment().month(month - 1).year(year)
  const eom = d.endOf('month').date()
  return range(1, eom + 1).map(m => {
    const date = moment().month(month - 1).year(year).date(m)
    const dayOfMonth = date.date()
    const key = date.format('D-M')
    return {
      date,
      dayOfMonth,
      key,
      isDisabled: (date.day() === 6 || date.day() === 5) || holidaysData.map[dayOfMonth],
      holiday: get(holidaysData, ['map', dayOfMonth, 'name']),
      isHoliday: !!holidaysData.map[dayOfMonth],
      start: get(reportData, [key, 'start'], 9),
      end: get(reportData, [key, 'end'], 18),
      note: get(reportData, [key, 'note'], {}),
      diff: get(reportData, [key, 'end'], 18) - get(reportData, [key, 'start'], 9),
      isPresent: !get(reportData, [key, 'type']) || get(reportData, [key, 'type']) === 'present'
    }
  })
}

export const calculateStats = (reportData, rows) => {

  let result = rows.reduce((acc, next) => {
    if (!next.isDisabled && !next.isHoliday) {
      acc.days = acc.days + 1
    }

    if (!next.isDisabled && !next.isHoliday && next.isPresent) {
      acc.hours = acc.hours + next.diff
    }
    if (next.isHoliday) {
      acc.holidays = acc.holidays + 1
    }

    if (get(reportData, [next.key, 'type']) === 'dayoff') {
      acc.daysOff = acc.daysOff + 1
    }
    if (get(reportData, [next.key, 'type']) === 'sick') {
      acc.sickDays = acc.sickDays + 1
    }
    if (get(reportData, [next.key, 'type']) === 'military') {
      acc.militaryDays = acc.militaryDays + 1
    }
    return acc
  }, {days: 0, hours: 0, daysOff: 0, sickDays: 0, militaryDays: 0, holidays: 0, isSubmitted: false})

  return {
    ...result,
    workDays: result.days - (result.daysOff + result.sickDays + result.militaryDays),
    isSubmitted: !!get(reportData, 'submitted.status')
  }
}
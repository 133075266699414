import firebase from 'firebase'
import moment from 'moment'

const firebaseConfig = {
  apiKey: "AIzaSyDEmCJxr3MZTdoNDYUyBHpHB-jWfIiXUUo",
  authDomain: "rapid-clock.firebaseapp.com",
  databaseURL: "https://rapid-clock.firebaseio.com",
  projectId: "rapid-clock",
  storageBucket: "",
  messagingSenderId: "579768872904",
  appId: "1:579768872904:web:2517e343c9b43047"
};
firebase.initializeApp(firebaseConfig);

export const database = firebase.database()
export const auth = firebase.auth

const provider = new auth.GoogleAuthProvider();
provider.setCustomParameters({
  hd: "rapidapi.com"
});


export const getUser = () => {
  const user = localStorage.getItem('rapidClockUser')
  return user ? JSON.parse(user) : {}
}

export const saveUser = async (user) => {
  const id = user.email.replace(/\./g, ',');
  const userToSave = {...user, id}
  return database.ref().update({['users/' + id]: userToSave}).then(() => {
    localStorage.setItem('rapidClockUser', JSON.stringify(userToSave))
    return userToSave
  }).catch(console.log)
}

export const login = () => {
  return auth().signInWithPopup(provider).then((result) => {
    const {displayName, email, photoURL, uid} = result.user;
    return saveUser({displayName, email, photoURL, uid})
  }).catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    const email = error.email;
    const credential = error.credential;
    console.log(errorCode, errorMessage, email, credential)
  });
}

export const logout = async () => {
  localStorage.removeItem('rapidClockUser')
  return auth().signOut()
}

export const saveReport = async (userId, month, year, data) => {
  return database.ref().update({[`reports/${month}-${year}/${userId}`]: data}).then(() => {
    console.log('Success')
  }).catch(console.log)
}

export const getReport = async (userId, month, year) => {
  return database.ref(`reports/${month}-${year}/${userId}`).once('value').then((snapshot) => {
    return snapshot.val()
  }).catch(console.log)
}

export const getUsers = async () => {
  return database.ref(`users/`).once('value').then((snapshot) => {
    const data = snapshot.val()
    return Object.keys(data).map(m => data[m]).filter(u => !u.removed)
  }).catch(console.log)
}


export const getIsAdmin = async (uid) => {
  return database.ref(`admins/${uid}`).once('value').then((snapshot) => {
    const data = snapshot.val()
    return data
  }).catch(console.log)
}

export const saveHoliday = async (month, year, data) => {
  const {name, date} = data
  const _date = moment(date).date()
  return database.ref().update({[`holidays/${month}-${year}/${_date}`]: {name, date}}).then(() => {
    console.log('Success')
  }).catch(console.log)
}

export const deleteHoliday = async (month, year, date) => {
  return database.ref(`holidays/${month}-${year}/${date}`).remove().then(() => {
    console.log('Success')
  }).catch(console.log)
}


export const getAllUsersMonthlyData = async (month, year) => {
  return database.ref(`reports/${month}-${year}`).once('value').then((results) => {
    return results.val()
  }).catch(console.log)
}


export const saveNotification = async (data, month, year) => {
  const key = database.ref().push().key
  return database.ref().update({[`notifications/${month}-${year}/${key}`]: {key, created: new Date(), text: data.text}}).then((results) => {
    return results.val()
  }).catch(console.log)
}


export const getNotificationsRef = (month, year) => {
  return database.ref(`notifications/${month}-${year}`)
}

export const deleteNotification = async (id, month, year) => {
  return database.ref(`notifications/${month}-${year}/${id}`).remove().then((results) => {
    return results.val()
  }).catch(console.log)
}

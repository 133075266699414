import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from "react-router-dom";
import 'semantic-ui-css/semantic.min.css'
import './App.css'
import Login from './Login'
import Layout from './Layout'
import { UserContext } from './DataContextProvider'

function App() {
  const [user] = useContext(UserContext)
  return (
    <Router>
      <Route path="/" render={() => user.email ? <Layout /> : <Redirect to={{ pathname: "/login", from: '/' }} />} />
      <Route path="/login" component={Login} />
    </Router>
  );
}

export default App;

import React, { useState } from 'react'
import { getUser } from './fb'

export const UserContext = new React.createContext({});
export const UsersContext = new React.createContext([]);
export const HolidaysContext = new React.createContext({});
export const IsAdminContext = new React.createContext(false);

const DataContextProvider = (props) => {
  const [user, setUser] = useState(getUser())
  const [users, setUsers] = useState([])
  const [holidays, setHolidays] = useState({ list: [], map: {} })
  const [isAdmin, setIsAdmin] = useState(false)
  return (
    <UserContext.Provider value={[user, setUser]}>
      <UsersContext.Provider value={[users, setUsers]}>
        <HolidaysContext.Provider value={[holidays, setHolidays]}>
          <IsAdminContext.Provider value={[isAdmin, setIsAdmin]}>
            {props.children}
          </IsAdminContext.Provider>
        </HolidaysContext.Provider>
      </UsersContext.Provider>
    </UserContext.Provider>
  );
}

export default DataContextProvider
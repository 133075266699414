import React, { useContext, useCallback, useEffect, useState } from 'react';
import { Grid, Card, Menu, Button, Label, Modal, Input } from 'semantic-ui-react'
import get from 'lodash/get'


const NoteModal = props => {
  const [text, setText] = useState('')
  useEffect(() => {
    setText(props.value || '')
  }, [props.value])
  return (
    <Modal size={'tiny'} open={!!props.open} onClose={props.onClose}>
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Content>
        <p>
          <Input value={text} placeholder="Notification Message" fluid onChange={(_, data) => setText(data.value)} />
        </p>

      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.onClose} negative>No</Button>
        <Button onClick={() => {
          text && props.onSave({ text })
          text && props.onClose()
        }} positive icon='checkmark' labelPosition='right' content='Save' />
      </Modal.Actions>
    </Modal>
  )
}


export default NoteModal
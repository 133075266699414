import React, { useCallback, useContext } from 'react';
import { Icon, Button } from 'semantic-ui-react'
import { withRouter } from "react-router";
import { login, logout } from './fb'
import { UserContext } from './DataContextProvider'




const Login = (props) => {

  const [, setUser] = useContext(UserContext)

  const handleLoginClick = useCallback(() => {
    login().then(usr => {
      setUser(usr)
      props.history.push('/')
    })
  }, [props.history, setUser])

  return (
    <div className="flex-col-center">
      <span className="mainLogo"><img src="https://rapidapi.com/static/rapidapi/logo.svg" alt=""/> <span>Clock</span></span> 
      <Button color='google plus' onClick={handleLoginClick}>
        <Icon name='google' /> Login With Google
      </Button>
    </div>
  );
}

export default withRouter(Login);

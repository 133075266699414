import React, { useMemo } from 'react';
import {  Menu, Select } from 'semantic-ui-react'
import range from 'lodash/range'
import moment from 'moment'

const MonthMenu = props => {

  const data = useMemo(() => {
    const prev = range(6).map((m) => {
      return moment().subtract(m, 'month')
    }).reverse()
    const next = range(1, 3).map((m) => {
      return moment().add(m, 'month')
    })
    return prev.concat(next)
  }, [])

  if (props.isMobile) {
    return <Select
      fluid
      options={data.map(m => ({ id: m.format('M-YYYY'), value: m.format('M-YYYY'), text: m.format('MMMM YYYY') }))}
      value={`${props.selectedMonth}-${props.selectedYear}`}
      onChange={(_, data) => props.onSelect(data.value.split('-')[0], data.value.split('-')[1])} />
  }

  return (
    <Menu color={'green'} vertical pointing>
      {data.map(m => {
        const key = m.format('M-YYYY')
        return <Menu.Item
          key={key}
          name={m.format('MMMM YYYY')}
          active={key === `${props.selectedMonth}-${props.selectedYear}`}
          onClick={() => props.onSelect(m.format('M'), m.format('YYYY'))}
        />
      })}
    </Menu>
  )
}

export default MonthMenu
